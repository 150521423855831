var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BasicInfo_wrapper" },
    [
      _c("el-card", { staticStyle: { width: "600px" } }, [
        _c(
          "div",
          { staticStyle: { "font-size": "20px", "font-weight": "500" } },
          [_vm._v("支持活动主页分享到朋友圈")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "16px",
              color: "rgb(200, 200, 200)",
              "margin-top": "20px"
            }
          },
          [_vm._v("开启后，允许用户分享活动主页到朋友圈")]
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "20px" } },
          [
            _c("el-switch", {
              attrs: { "active-color": "#13ce66", "inactive-color": "gray" },
              model: {
                value: _vm.shareOpen,
                callback: function($$v) {
                  _vm.shareOpen = $$v
                },
                expression: "shareOpen"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "20px" } },
          [
            _c(
              "el-button",
              {
                staticClass: "mt10",
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.setShare()
                  }
                }
              },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }