<template>
  <div class="BasicInfo_wrapper">
    <el-card style="width:600px">
      <div style="font-size: 20px;font-weight: 500;">支持活动主页分享到朋友圈</div>
      <div style="font-size: 16px; color: rgb(200, 200, 200);margin-top:20px">开启后，允许用户分享活动主页到朋友圈</div>
      <div style="margin-top:20px">
        <el-switch
          v-model="shareOpen"
          active-color="#13ce66"
          inactive-color="gray">
        </el-switch>
      </div>
      <div style="margin-top:20px">
        <el-button type="primary" @click="setShare()" class="mt10">保存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
  import ActivityAjax from "@/utils/https/modules/Activity.request.js";
  export default {
    name: "ShareSet", // 基本信息
    props: {
      activityID: String,
    },
    data() {
      return {
        baseInfo: {
          showAgreement: true,
          buttonContent: ''
        },
        shareOpen:false,
      };
    },
    computed: {},
    watch: {},
    methods: {
      // 获取分享设置权限（朋友圈）/v1/activity/share_moment_flag/get
      async getShareMomentFlag () {
        try {
          const {data} = await ActivityAjax.getShareMomentFlag(this.activityID);
          if (data) {
            if(data.shareMomentFlag=="true"){
              this.shareOpen = true;
            }else{
              this.shareOpen = false;
            }
          }
        } catch (error) {
          this.$message.error(error);
        }
      },
      async setShare() {
        try {
          const {data} = await ActivityAjax.setShareMomentFlag({
            id: this.activityID,
            shareMomentFlag: this.shareOpen?"true":"false",
          });
          this.$message.success("设置成功");
        } catch (error) {
          this.$message.error(error);
        }
      },
    },
    created() {},
    mounted() {
      this.getShareMomentFlag();
    },
  };
</script>

<style lang="scss" scoped>
  .radio {
    display: flex;
    flex-direction: column;
    .el-radio {
      margin-top: 6px;
    }
  }
</style>
